// const URL =
//   process.env.REACT_APP_MODE === 'production'
//     ? 'https://wa.aisensy.com'
//     : 'http://localhost:5000';
const URL =
process.env.LINK_GEN_BACKEND_URL ||
  (process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://wa.aisensy.com'
    : 'https://wa.aisensy.com');

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '882865407668-dl8a107bcb65kl8ttqe8e6j2usu3cm40.apps.googleusercontent.com';

export { URL, GOOGLE_CLIENT_ID };
